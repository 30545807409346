<template lang="pug">
  include ../../assets/pug/index
  div
    div
      .mb-7.d-flex.justify-end
        v-btn.btn-primary.mx-2(@click="viewForm = !viewForm")
          span
            v-icon {{ viewForm ? 'mdi-close' : 'mdi-plus' }}
            span {{ pageInfo ? $t('editTestingCenter') : $t('addTestingCenter') }}
      .d-flex.w-50.p-2.mb-5(
        v-if="viewForm || pageInfo"
        style="margin: 0 auto; border: 1px solid #ccc"
      )
        +inputValidation('tcObj.name', 'nameTestingCenter', '["required"]')(:disabled="pageInfo && !viewForm").mr-4
        +inputValidation('tcObj.address', 'address', '["required"]')(:disabled="pageInfo && !viewForm").mr-4
        +btn('success', 'save')(@click="validation()" :loading="isLoad").mt-3.ml-6
</template>

<script>
import { validate, validationField } from '@/mixins/validation'
import { mapActions } from 'vuex'

export default {
  name: 'testingCenterForm',
  props: {
    setObject: Object,
    pageInfo: Boolean
  },
  data () {
    return {
      viewForm: false,
      isLoad: false,
      tcObj: {
        name: '',
        address: ''
      },
      validationField
    }
  },
  validations: validate.tcObj,
  methods: {
    ...mapActions(['setTestingCenter', 'updateTestingCenter']),
    validation () {
      this.$v.$touch()
      !this.pageInfo && !this.$v.$invalid && this.setTestingCenter(this.tcObj)
      this.pageInfo && !this.$v.$invalid && 
      this.updateTestingCenter(this.tcObj).then(() => this.viewForm = false)
    }
  },
  mounted () {
    if (this.pageInfo) this.tcObj = this.setObject
  }
}
</script>
