<template lang="pug">
  div
    v-tabs(v-model="tabs")
      v-tab {{$t('testingCenters')}}
    v-tabs-items(v-model="tabs")
      v-tab-item
        TestingCenterList
</template>



<script>
import TestingCenterList from './TestingCenterList'

export default {
  name: 'testingCenter',
  components: {
    TestingCenterList
  },
  data() {
    return {
      tabs: 0
    }
  },
  mounted()  {
    this.$store.commit('setDataTitle', this.$i18n.t('testingCenters'))
  },
}

</script>
