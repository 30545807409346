<template lang="pug">
  v-card(flat)
    TestingCenterForm
    Table(
      :headers="headers"
      :items="testingCenters"
      :links="links"
      :load="tableLoader"
    )
</template>

<script>
import TestingCenterForm from './TestingCenterForm'
import { mapState } from 'vuex'

export default {
  name: 'testingCenterList',
  components: {
    TestingCenterForm
  },
  data() {
    return {
      links: [
        {
          name: 'testingCenterInfo',
          icon: 'mdi-information-outline'
        },
      ],
      headers: [
        { text: 'ID', value: 'id'},
        { text: this.$i18n.t('nameTestingCenter'), value: 'name'},
        { text: this.$i18n.t('address'), value: 'address'},
        { text: this.$i18n.t('action'), value: 'actions'},
      ],
      tableLoader: false
    }
  },
  computed: {
    ...mapState({
      testingCenters: state => state.stand.standData.testing_centers
    })
  },
}

</script>
